"use strict";
/* eslint-disable react/prop-types */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.SidebarPanel = void 0;
var React = __importStar(require("react"));
var SidebarPanel = function (_a) {
    var _b;
    var isVisible = _a.isVisible, isOpen = _a.isOpen, depth = _a.depth, widthPx = _a.widthPx, children = _a.children;
    var className = ('sidebar-panel'
        + (isVisible ? ' sidebar-panel--visible' : '')
        + (isOpen ? ' sidebar-panel--open' : ''));
    var zIndex = -depth * 2;
    var isClosing = isVisible && !isOpen;
    if (isClosing) {
        // When closing, make sure this panel displays behind any new panel that is opening
        zIndex--;
    }
    var style = (_b = {},
        // See https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors.
        _b['--z-index'] = zIndex,
        _b);
    if (widthPx) {
        style['--width'] = widthPx + 'px';
    }
    return (React.createElement("div", { className: className, style: style }, children));
};
exports.SidebarPanel = SidebarPanel;
