"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.gotoPage = exports.openPageExplorer = exports.closePageExplorer = void 0;
var admin = __importStar(require("../../api/admin"));
var actions_1 = require("../../utils/actions");
var wagtailConfig_1 = require("../../config/wagtailConfig");
var getPageSuccess = actions_1.createAction('GET_PAGE_SUCCESS', function (id, data) { return ({ id: id, data: data }); });
var getPageFailure = actions_1.createAction('GET_PAGE_FAILURE', function (id, error) { return ({ id: id, error: error }); });
/**
 * Gets a page from the API.
 */
function getPage(id) {
    return function (dispatch) { return admin.getPage(id).then(function (data) {
        dispatch(getPageSuccess(id, data));
    }, function (error) {
        dispatch(getPageFailure(id, error));
    }); };
}
var getChildrenStart = actions_1.createAction('GET_CHILDREN_START', function (id) { return ({ id: id }); });
var getChildrenSuccess = actions_1.createAction('GET_CHILDREN_SUCCESS', function (id, items, meta) { return ({ id: id, items: items, meta: meta }); });
var getChildrenFailure = actions_1.createAction('GET_CHILDREN_FAILURE', function (id, error) { return ({ id: id, error: error }); });
/**
 * Gets the children of a node from the API.
 */
function getChildren(id, offset) {
    if (offset === void 0) { offset = 0; }
    return function (dispatch) {
        dispatch(getChildrenStart(id));
        return admin.getPageChildren(id, {
            offset: offset,
        }).then(function (_a) {
            var items = _a.items, meta = _a.meta;
            var nbPages = offset + items.length;
            dispatch(getChildrenSuccess(id, items, meta));
            // Load more pages if necessary. Only one request is created even though
            // more might be needed, thus naturally throttling the loading.
            if (nbPages < meta.total_count && nbPages < wagtailConfig_1.MAX_EXPLORER_PAGES) {
                dispatch(getChildren(id, nbPages));
            }
        }, function (error) {
            dispatch(getChildrenFailure(id, error));
        });
    };
}
var getTranslationsStart = actions_1.createAction('GET_TRANSLATIONS_START', function (id) { return ({ id: id }); });
var getTranslationsSuccess = actions_1.createAction('GET_TRANSLATIONS_SUCCESS', function (id, items) { return ({ id: id, items: items }); });
var getTranslationsFailure = actions_1.createAction('GET_TRANSLATIONS_FAILURE', function (id, error) { return ({ id: id, error: error }); });
/**
 * Gets the translations of a node from the API.
 */
function getTranslations(id) {
    return function (dispatch) {
        dispatch(getTranslationsStart(id));
        return admin.getAllPageTranslations(id, { onlyWithChildren: true }).then(function (items) {
            dispatch(getTranslationsSuccess(id, items));
        }, function (error) {
            dispatch(getTranslationsFailure(id, error));
        });
    };
}
var openPageExplorerPrivate = actions_1.createAction('OPEN_EXPLORER', function (id) { return ({ id: id }); });
exports.closePageExplorer = actions_1.createAction('CLOSE_EXPLORER');
function openPageExplorer(id) {
    return function (dispatch, getState) {
        var nodes = getState().nodes;
        var page = nodes[id];
        dispatch(openPageExplorerPrivate(id));
        if (!page) {
            dispatch(getChildren(id));
            if (id !== 1) {
                dispatch(getTranslations(id));
            }
        }
        // We need to get the title of the starting page, only if it is not the site's root.
        var isNotRoot = id !== 1;
        if (isNotRoot) {
            dispatch(getPage(id));
        }
    };
}
exports.openPageExplorer = openPageExplorer;
var gotoPagePrivate = actions_1.createAction('GOTO_PAGE', function (id, transition) { return ({ id: id, transition: transition }); });
function gotoPage(id, transition) {
    return function (dispatch, getState) {
        var nodes = getState().nodes;
        var page = nodes[id];
        dispatch(gotoPagePrivate(id, transition));
        if (page && !page.isFetchingChildren && !(page.children.count > 0)) {
            dispatch(getChildren(id));
        }
        if (page && !page.isFetchingTranslations && page.translations == null) {
            dispatch(getTranslations(id));
        }
    };
}
exports.gotoPage = gotoPage;
