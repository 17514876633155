"use strict";
exports.__esModule = true;
var Sidebar_1 = require("../../components/Sidebar");
var LinkMenuItem_1 = require("../../components/Sidebar/menu/LinkMenuItem");
var SubMenuItem_1 = require("../../components/Sidebar/menu/SubMenuItem");
var PageExplorerMenuItem_1 = require("../../components/Sidebar/menu/PageExplorerMenuItem");
var WagtailBranding_1 = require("../../components/Sidebar/modules/WagtailBranding");
var Search_1 = require("../../components/Sidebar/modules/Search");
var MainMenu_1 = require("../../components/Sidebar/modules/MainMenu");
window.telepath.register('wagtail.sidebar.LinkMenuItem', LinkMenuItem_1.LinkMenuItemDefinition);
window.telepath.register('wagtail.sidebar.SubMenuItem', SubMenuItem_1.SubMenuItemDefinition);
window.telepath.register('wagtail.sidebar.PageExplorerMenuItem', PageExplorerMenuItem_1.PageExplorerMenuItemDefinition);
window.telepath.register('wagtail.sidebar.WagtailBrandingModule', WagtailBranding_1.WagtailBrandingModuleDefinition);
window.telepath.register('wagtail.sidebar.SearchModule', Search_1.SearchModuleDefinition);
window.telepath.register('wagtail.sidebar.MainMenuModule', MainMenu_1.MainMenuModuleDefinition);
document.addEventListener('DOMContentLoaded', function () {
    Sidebar_1.initSidebar();
});
