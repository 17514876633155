"use strict";
exports.__esModule = true;
exports.GET_TRANSLATIONS_FAILURE = exports.GET_CHILDREN_FAILURE = exports.GET_PAGE_FAILURE = exports.GET_TRANSLATIONS_SUCCESS = exports.GET_TRANSLATIONS_START = exports.GET_CHILDREN_SUCCESS = exports.GET_CHILDREN_START = exports.GET_PAGE_SUCCESS = void 0;
var explorer_1 = require("./explorer");
var defaultPageState = {
    id: 0,
    isFetchingChildren: false,
    isFetchingTranslations: false,
    isError: false,
    children: {
        items: [],
        count: 0,
    },
    meta: {
        status: {
            status: '',
            live: false,
            has_unpublished_changes: true
        },
        parent: null,
        children: {},
    },
};
exports.GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
exports.GET_CHILDREN_START = 'GET_CHILDREN_START';
exports.GET_CHILDREN_SUCCESS = 'GET_CHILDREN_SUCCESS';
exports.GET_TRANSLATIONS_START = 'GET_TRANSLATIONS_START';
exports.GET_TRANSLATIONS_SUCCESS = 'GET_TRANSLATIONS_SUCCESS';
exports.GET_PAGE_FAILURE = 'GET_PAGE_FAILURE';
exports.GET_CHILDREN_FAILURE = 'GET_CHILDREN_FAILURE';
exports.GET_TRANSLATIONS_FAILURE = 'GET_TRANSLATIONS_FAILURE';
/**
 * A single page node in the explorer.
 */
var node = function (state, action) {
    if (state === void 0) { state = defaultPageState; }
    switch (action.type) {
        case exports.GET_PAGE_SUCCESS:
            return Object.assign({}, state, action.payload.data, {
                isError: false,
            });
        case exports.GET_CHILDREN_START:
            return Object.assign({}, state, {
                isFetchingChildren: true,
            });
        case exports.GET_TRANSLATIONS_START:
            return Object.assign({}, state, {
                isFetchingTranslations: true,
            });
        case exports.GET_CHILDREN_SUCCESS:
            return Object.assign({}, state, {
                isFetchingChildren: false,
                isError: false,
                children: {
                    items: state.children.items.slice().concat(action.payload.items.map(function (item) { return item.id; })),
                    count: action.payload.meta.total_count,
                },
            });
        case exports.GET_TRANSLATIONS_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            var translations_1 = new Map();
            action.payload.items.forEach(function (item) {
                translations_1.set(item.meta.locale, item.id);
            });
            return Object.assign({}, state, {
                isFetchingTranslations: false,
                isError: false,
                translations: translations_1,
            });
        case exports.GET_PAGE_FAILURE:
        case exports.GET_CHILDREN_FAILURE:
        case exports.GET_TRANSLATIONS_FAILURE:
            return Object.assign({}, state, {
                isFetchingChildren: false,
                isFetchingTranslations: true,
                isError: true,
            });
        default:
            return state;
    }
};
var defaultState = {};
/**
 * Contains all of the page nodes in one object.
 */
function nodes(state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case explorer_1.OPEN_EXPLORER: {
            return Object.assign({}, state, (_a = {},
                _a[action.payload.id] = Object.assign({}, defaultPageState),
                _a));
        }
        case exports.GET_PAGE_SUCCESS:
        case exports.GET_CHILDREN_START:
        case exports.GET_TRANSLATIONS_START:
        case exports.GET_PAGE_FAILURE:
        case exports.GET_CHILDREN_FAILURE:
        case exports.GET_TRANSLATIONS_FAILURE:
            return Object.assign({}, state, (_b = {},
                // Delegate logic to single-node reducer.
                _b[action.payload.id] = node(state[action.payload.id], action),
                _b));
        case exports.GET_CHILDREN_SUCCESS:
        case exports.GET_TRANSLATIONS_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            var newState_1 = Object.assign({}, state, (_c = {},
                _c[action.payload.id] = node(state[action.payload.id], action),
                _c));
            action.payload.items.forEach(function (item) {
                newState_1[item.id] = Object.assign({}, defaultPageState, item);
            });
            return newState_1;
        case explorer_1.CLOSE_EXPLORER: {
            return defaultState;
        }
        default:
            return state;
    }
}
exports["default"] = nodes;
