"use strict";
/* eslint-disable react/prop-types */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
exports.WagtailBrandingModuleDefinition = void 0;
var React = __importStar(require("react"));
var WagtailBranding = function (_a) {
    var homeUrl = _a.homeUrl, images = _a.images, strings = _a.strings, currentPath = _a.currentPath, navigate = _a.navigate;
    var brandingLogo = React.useMemo(function () {
        return document.querySelector('[data-wagtail-sidebar-branding-logo]');
    }, []);
    var hasCustomBranding = brandingLogo && brandingLogo.innerHTML !== '';
    var onClick = function (e) {
        // Do not capture click events with modifier keys or non-main buttons.
        if (e.ctrlKey ||
            e.shiftKey ||
            e.metaKey ||
            (e.button && e.button !== 0)) {
            return;
        }
        e.preventDefault();
        navigate(homeUrl);
    };
    // Render differently if custom branding is provided.
    // This will only ever render once, so rendering before hooks is ok.
    if (hasCustomBranding) {
        return (React.createElement("a", { className: "sidebar-custom-branding", href: homeUrl, "aria-label": strings.DASHBOARD, "aria-current": currentPath === homeUrl ? 'page' : undefined, dangerouslySetInnerHTML: { __html: brandingLogo ? brandingLogo.innerHTML : '' } }));
    }
    // Tail wagging
    // If the pointer changes direction 8 or more times without leaving, wag the tail!
    var lastMouseX = React.useRef(0);
    var lastDir = React.useRef('r');
    var dirChangeCount = React.useRef(0);
    var _b = __read(React.useState(false), 2), isWagging = _b[0], setIsWagging = _b[1];
    var onMouseMove = function (e) {
        var mouseX = e.pageX;
        var dir = (mouseX > lastMouseX.current) ? 'r' : 'l';
        if (mouseX !== lastMouseX.current && dir !== lastDir.current) {
            dirChangeCount.current += 1;
        }
        if (dirChangeCount.current > 8) {
            setIsWagging(true);
        }
        lastMouseX.current = mouseX;
        lastDir.current = dir;
    };
    var onMouseLeave = function () {
        setIsWagging(false);
        dirChangeCount.current = 0;
    };
    var desktopClassName = ('sidebar-wagtail-branding'
        + (isWagging ? ' sidebar-wagtail-branding--wagging' : ''));
    return (React.createElement("a", { className: desktopClassName, href: homeUrl, "aria-label": strings.DASHBOARD, "aria-current": currentPath === homeUrl ? 'page' : undefined, onClick: onClick, onMouseMove: onMouseMove, onMouseLeave: onMouseLeave },
        React.createElement("div", { className: "sidebar-wagtail-branding__icon-wrapper" },
            React.createElement("img", { className: "sidebar-wagtail-branding__icon", "data-part": "body", src: images.desktopLogoBody, alt: "" }),
            React.createElement("img", { className: "sidebar-wagtail-branding__icon", "data-part": "tail", src: images.desktopLogoTail, alt: "" }),
            React.createElement("img", { className: "sidebar-wagtail-branding__icon", "data-part": "eye--open", src: images.desktopLogoEyeOpen, alt: "" }),
            React.createElement("img", { className: "sidebar-wagtail-branding__icon", "data-part": "eye--closed", src: images.desktopLogoEyeClosed, alt: "" }))));
};
var WagtailBrandingModuleDefinition = /** @class */ (function () {
    function WagtailBrandingModuleDefinition(homeUrl, images) {
        this.homeUrl = homeUrl;
        this.images = images;
    }
    WagtailBrandingModuleDefinition.prototype.render = function (_a) {
        var strings = _a.strings, key = _a.key, navigate = _a.navigate, currentPath = _a.currentPath;
        return (React.createElement(WagtailBranding, { key: key, homeUrl: this.homeUrl, images: this.images, strings: strings, navigate: navigate, currentPath: currentPath }));
    };
    return WagtailBrandingModuleDefinition;
}());
exports.WagtailBrandingModuleDefinition = WagtailBrandingModuleDefinition;
